import React from "react"
import { Routes, Route } from "react-router-dom"
import ChoicePerson from "./components/ChoicePerson"
import UserConf from "./components/UserConf"
import "./styles/global.css"
import styles from "./App.module.css"

const App = () => {
  return (
    <div className={styles.app}>
      <Routes>
        <Route path="/" element={<ChoicePerson />} />
        <Route path="person" element={<UserConf />} />
      </Routes>
    </div>
  )
}

export default App
