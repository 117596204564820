import color1 from "../images/color-1.png"
import color2 from "../images/color-2.png"
import color3 from "../images/color-3.png"
import color4 from "../images/color-4.png"
import color5 from "../images/color-5.png"
import color6 from "../images/color-6.png"
import color7 from "../images/color-7.png"
import color8 from "../images/color-8.png"
import color9 from "../images/color-9.png"
import color10 from "../images/color-10.png"
import color11 from "../images/color-11.png"
import color12 from "../images/color-12.png"
import color13 from "../images/color-13.png"
import color14 from "../images/color-14.png"
import color15 from "../images/color-15.png"
import color16 from "../images/color-16.png"
import color17 from "../images/color-17.png"
import color18 from "../images/color-18.png"
import color19 from "../images/color-19.png"
import color20 from "../images/color-20.png"
import color21 from "../images/color-21.png"
import color22 from "../images/color-22.png"
import color23 from "../images/color-23.png"
import color24 from "../images/color-24.png"
import color25 from "../images/color-25.png"

const colors = [
  {
    name: `Принт “Сиба-ину”`,
    image: color1,
  },
  {
    name: `Принт “Кролики”`,
    image: color2,
  },
  {
    name: `Принт “Мишки”`,
    image: color3,
  },
  {
    name: `Принт “Глинтвейн”`,
    image: color4,
  },
  {
    name: `Принт “Алиса”`,
    image: color5,
  },
  {
    name: `Принт “Звездная ночь”`,
    image: color6,
  },
  {
    name: `Принт “Цветы на темном”`,
    image: color7,
  },
  {
    name: `Принт “Драконы”`,
    image: color8,
  },
  {
    name: `Принт “Королева подушек”`,
    image: color9,
  },
  {
    name: `Принт “Неоновые коты”`,
    image: color10,
  },
  {
    name: `Принт “Гранаты”`,
    image: color11,
  },
  {
    name: `Принт “Лисички”`,
    image: color12,
  },
  {
    name: `Принт “Обезъяны”`,
    image: color13,
  },
  {
    name: `Однотонная ткань “Голубая”`,
    image: color14,
  },
  {
    name: `Однотонная ткань “Графитовая”`,
    image: color15,
  },
  {
    name: `Однотонная ткань “Топаз”`,
    image: color16,
  },
  {
    name: `Однотонная ткань “Сливовая”`,
    image: color17,
  },
  {
    name: `Однотонная ткань “Бежевая”`,
    image: color18,
  },
  {
    name: `Однотонная ткань “Красная”`,
    image: color19,
  },
  {
    name: `Однотонная ткань “Винная”`,
    image: color20,
  },
  {
    name: `Однотонная ткань “Оранжевая”`,
    image: color21,
  },
  {
    name: `Однотонная ткань “Белая”`,
    image: color22,
  },
  {
    name: `Однотонная ткань “Фисташковый”`,
    image: color23,
  },
  {
    name: `Однотонная ткань “Черная”`,
    image: color24,
  },
  {
    name: `Однотонная ткань “Пудровая”`,
    image: color25,
  },
]

export default colors