import React, { Fragment } from "react"
import styles from "./SizeBlock.module.css"
import SizeUp from "./SizeUp"
import SizeDown from "./SizeDown"

let indexUp = 0
let indexDown = 0

const SizeBlock = ({
  checkFormulaUp,
  checkFormulaDown,
  sewing,
  womanState,
  dispatch
}) => {
  const handleAddUp = () => {
    indexUp++

    dispatch({
      type: "add_up",
      id: "",
      src: "",
      name: "",
    })
  }

  const removeUp = () => {
    indexUp--
  }

  const handleAddDown = () => {
    dispatch({
      type: "add_down",
      id: "",
      src: "",
      name: "",
    })

    indexDown++
  }

  const removeDown = () => {
    indexDown--
  }

  if (sewing === "") {
    indexUp = 0
    indexDown = 0
  }

  const handleFason = (e) => {
    dispatch({
      type: "add_fason_up",
      id: e.target.id,
      src: e.target.src,
      name: e.target.name,
    })
  }

  const clearFason = (e) => {
    dispatch({
      type: "add_fason_up",
      id: e.target.id,
      src: "",
      name: "",
    })
  }

  const removeFason = (e) => {
    dispatch({
      type: "remove_up",
      id: e.target.id,
    })

    removeUp()
  }

  const handleColor = (e) => {
    dispatch({
      type: "add_color_up",
      id: e.target.id,
      src: e.target.src,
      name: e.target.name,
    })
  }

  const clearColor = (e) => {
    dispatch({
      type: "add_color_up",
      id: e.target.id,
      src: "",
      name: "",
    })
  }

  const handleOption = (e) => {
    dispatch({
      type: "add_option_up",
      id: e.target.id,
      src: e.target.src,
      name: e.target.name,
    })
  }

  const clearOption = (e) => {
    dispatch({
      type: "add_option_up",
      id: e.target.id,
      src: "",
      name: "",
    })
  }

  // const handleOptionAdditional = (e) => {
  //   dispatch({
  //     type: "add_option_up_additional",
  //     id: e.target.id,
  //     src: e.target.src,
  //     name: e.target.name,
  //   })
  // }

  // const clearOptionAdditional = (e) => {
  //   dispatch({
  //     type: "add_option_up_additional",
  //     id: e.target.id,
  //     src: "",
  //     name: "",
  //   })
  // }

  const handleFasonDown = (e) => {
    dispatch({
      type: "add_fason_down",
      id: e.target.id,
      src: e.target.src,
      name: e.target.name,
    })
  }

  const clearFasonDown = (e) => {
    dispatch({
      type: "add_fason_down",
      id: e.target.id,
      src: "",
      name: "",
    })
  }

  const removeFasonDown = (e) => {
    dispatch({
      type: "remove_down",
      id: e.target.id,
    })

    removeDown()
  }

  const handleColorDown = (e) => {
    dispatch({
      type: "add_color_down",
      id: e.target.id,
      src: e.target.src,
      name: e.target.name,
    })
  }

  const clearColorDown = (e) => {
    dispatch({
      type: "add_color_down",
      id: e.target.id,
      src: "",
      name: "",
    })
  }

  const handleOptionDown = (e) => {
    dispatch({
      type: "add_option_down",
      id: e.target.id,
      src: e.target.src,
      name: e.target.name,
    })
  }

  const clearOptionDown = (e) => {
    dispatch({
      type: "add_option_down",
      id: e.target.id,
      src: "",
      name: "",
    })
  }

  // console.log(womanState)

  return (
    <div className={
      `${styles.sizeData}${
        sewing === "size"
          ? (" " + styles.sizeFull)
          : (sewing === "individ" ? " " + styles.enterFull : "")
      }`
    }>
      <SizeUp
        index={0}
        womanState={womanState}
        handleFason={handleFason}
        clearFason={clearFason}
        removeFason={removeFason}
        handleColor={handleColor}
        clearColor={clearColor}
        handleOption={handleOption}
        clearOption={clearOption}
        checkFormulaUp={checkFormulaUp}
        // handleOptionAdditional={handleOptionAdditional}
        // clearOptionAdditional={clearOptionAdditional}
      />

      {indexUp !== 0 && womanState.fason_up.length === (indexUp + 1) && (
        womanState.fason_up.map((item, i) => {
          if (i === 0) return null

          return (
            <Fragment key={item.index}>
              <div className={styles.line20}></div>
              <SizeUp
                index={i}
                womanState={womanState}
                handleFason={handleFason}
                clearFason={clearFason}
                removeFason={removeFason}
                handleColor={handleColor}
                clearColor={clearColor}
                handleOption={handleOption}
                clearOption={clearOption}
                checkFormulaUp={checkFormulaUp}
                // handleOptionAdditional={handleOptionAdditional}
                // clearOptionAdditional={clearOptionAdditional}
              />
            </Fragment>
          )
        })
      )}

      {womanState.fason_up[indexUp].id && womanState.color_up[indexUp].id && (
        <button
          type="button"
          className={styles.buttonAdd}
          onClick={handleAddUp}
        >
          Добавить фасон для верха
        </button>
      )}

      <div className={styles.line40}></div>

      <SizeDown
        index={0}
        womanState={womanState}
        handleFasonDown={handleFasonDown}
        clearFasonDown={clearFasonDown}
        removeFasonDown={removeFasonDown}
        handleColorDown={handleColorDown}
        clearColorDown={clearColorDown}
        handleOptionDown={handleOptionDown}
        clearOptionDown={clearOptionDown}
        checkFormulaDown={checkFormulaDown}
      />

      {indexDown !== 0 && womanState.fason_down.length === (indexDown + 1) && (
        womanState.fason_down.map((item, i) => {
          if (i === 0) return null

          return (
            <Fragment key={item.index}>
              <div className={styles.line20}></div>
              <SizeDown
                index={i}
                womanState={womanState}
                handleFasonDown={handleFasonDown}
                clearFasonDown={clearFasonDown}
                removeFasonDown={removeFasonDown}
                handleColorDown={handleColorDown}
                clearColorDown={clearColorDown}
                handleOptionDown={handleOptionDown}
                clearOptionDown={clearOptionDown}
                checkFormulaDown={checkFormulaDown}
              />
            </Fragment>
          )
        })
      )}

      {womanState.fason_down[indexDown].id && womanState.color_down[indexDown].id && (
        <button
          type="button"
          className={`${styles.buttonAdd} ${styles.buttonDown}`}
          onClick={handleAddDown}
        >
          Добавить фасон для низа
        </button>
      )}

    </div>
  )
}

export default SizeBlock
