import fason1 from "../images/fason-1.png"
import fason2 from "../images/fason-2.png"
import fason3 from "../images/fason-3.png"
import fason4 from "../images/fason-4.png"
import fason5 from "../images/fason-5.png"

const fasonsUp = [
  {
    name: `Лиф с вытачками фасона “М1”`,
    image: fason4,
  },
  {
    name: `Лиф цельнокроеный фасона “М2”`,
    image: fason1,
  },
  {
    name: `Лиф с иметацией косточек фасона “М4”`,
    image: fason5,
  },
  {
    name: `Комбинированный лиф фасона “М3”`,
    image: fason2,
  },
  {
    name: `Кружевной лиф фасона “М3”`,
    image: fason3,
  },
  
 
]

export default fasonsUp