import React, { useState, useEffect, useCallback } from "react"
import { useCookies } from "react-cookie"
import { Swiper, SwiperSlide } from "swiper/react"
import { useNavigate } from "react-router-dom"
import CoCart from "../services/co-cart-api"
import persons from "../data/persons"
import { ReactComponent as Plus } from "../images/plus.svg"
import styles from "./ChoicePerson.module.css"
import "swiper/css"

const PRODUCT_ADD_ID = 60288

const ChoicePerson = () => {
  const navigate = useNavigate()

  const [cart, setCart] = useState([])
  const [cookies] = useCookies(["cart_key"])

  const choisePerson = () => {
    navigate("/person")
  }

  const handleClick = () => {
    window.open(
      `https://la-lama.ru/?add-to-cart=${PRODUCT_ADD_ID}&cart_key=${cookies.cart_key}`
    )
  }

  const getCart = useCallback(async () => {
    try {
      const response = await CoCart.get(`cart?cart_key=${cookies.cart_key}`)
      setCart(response.data.items)
    } catch (err) {
      console.log(err)
    }
  }, [cookies.cart_key])

  useEffect(() => {
    getCart()
  }, [getCart])

  return (
    <div className={styles.choicePerson}>
      <div className={styles.wrapper}>
        <Swiper
          slidesPerView={2}
          spaceBetween={-115}
          centeredSlides={true}
        >
          <SwiperSlide>
            <div className={styles.personPng} onClick={choisePerson}>
              <img src={persons[0]} alt="Woman" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.personPng}>
              <img src={persons[1]} alt="Baby" />
              <div className="overlay"></div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.personPng}>
              <img src={persons[2]} alt="Pregnant" />
              <div className="overlay"></div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.personPng}>
              <img src={persons[3]} alt="Man" />
              <div className="overlay"></div>
            </div>
          </SwiperSlide>
        </Swiper>
        
        <div className={styles.addPeson} onClick={choisePerson}>
          <Plus />
        </div>

        {cart.length > 0 && (
          <div className={styles.checkoutBlock}>
            <button
              onClick={handleClick}
              className={styles.checkout}
            >
              Перейти к оформлению заказа
            </button>

            <button
              onClick={choisePerson}
              className={styles.checkout}
            >
              Добавить еще
            </button>
          </div >
        )}

      </div>
    </div>
  )
}

export default ChoicePerson
