import React from "react"
import styles from "./UserConf.module.css"
import fasonsUp from "../data/fasonsUp"
import colors from "../data/colors"
import options from "../data/options"

const SizeUp = ({
  index,
  womanState,
  handleFason,
  clearFason,
  removeFason,
  handleColor,
  clearColor,
  handleOption,
  clearOption,
  checkFormulaUp,
}) => {
  const fasonUpArr =  womanState.fason_up
  const colorUpArr = womanState.color_up
  const optionUpArr = womanState.option_up

  return (
    <div className={styles.sizeBlock}>
      <div className={styles.name}>ВЕРХ</div>
      {index > 0 && (
        <button
          type="button"
          className={styles.removeButton}
          id={`removeFason-${index}`}
          onClick={removeFason}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16"><path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"></path></svg>
        </button>
      )}

      {/* Block of selected options */}
      {fasonUpArr[index].id && (
        <>
          <div className={styles.selected}>
            {fasonUpArr[index].id && (
              <div className={styles.selectedItem}>
                <img src={fasonUpArr[index].src} alt="" />
                {fasonUpArr[index].id && !colorUpArr[index].id && (
                  <button
                    id={`clearFason-${index}`}
                    onClick={clearFason}
                    type="button"
                  >
                    Поменять фасон
                  </button>
                )}
              </div>
            )}
            {colorUpArr[index].id && (
              <div className={styles.selectedItem}>
                <img src={colorUpArr[index].src} alt="" />
                {colorUpArr[index].id && !optionUpArr[index].id && (
                  <button
                    type="button"
                    id={`clearColor-${index}`}
                    onClick={clearColor}
                  >
                    Поменять расцветку
                  </button>
                )}
              </div>
            )}
            {optionUpArr[index].id && (
              <div className={styles.selectedItem}>
                <img src={optionUpArr[index].src} alt="" />
                {optionUpArr[index].id && (
                  <button
                    type="button"
                    id={`clearOption-${index}`}
                    onClick={clearOption}
                    className={styles.changed}
                  >
                    Убрать опции
                  </button>
                )}
              </div>
            )}
          </div>
          {/* {optionUpArr[index].id && (
            <div>
              <button
                type="button"
                id={`clearOption-${index}`}
                onClick={clearOption}
                className={styles.changed}
              >
                Убрать опции
              </button>
            </div>
          )} */}
        </>
      )}

      {/* Hide this block if model has been added */}
      {!fasonUpArr[index].id && (
        <div className={styles.sizeFason}>
          <div className={styles.title}>КАКОЙ БУДЕТ ФАСОН?</div>
          <div className={styles.fasonBlock}>
            {fasonsUp.map((fason, i) => (
              <div key={fason.name} className={styles.fasonItem}>
                <img
                  id={`fason${i + 1}-${index}`}
                  src={fason.image}
                  name={fason.name}
                  alt=""
                  onClick={handleFason}
                />
              </div>
            ))}
          </div>
          {
            checkFormulaUp ? null : <div className={styles.overlay}></div>
          }
        </div>
      )}

      {/* Color block show if model has been added and hide this block if color has been added */}
      {fasonUpArr[index].id && !colorUpArr[index].id && (
        <div className={styles.sizeColor}>
          <div className={styles.title}>КАКОЙ БУДЕТ РАСЦВЕТКА?</div>
          <div className={styles.colorBlock}>
            {colors.map((color, i) => (
              <div key={color.name} className={styles.colorItem}>
                <img
                  id={`color${i + 1}-${index}`}
                  src={color.image}
                  name={color.name}
                  alt=""
                  onClick={handleColor}
                />
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Options block show if color has been added, hide this block if option has been added or skipped */}
      {colorUpArr[index].id && !optionUpArr[index].id && (
        <div className={styles.sizeOption}>
          <div className={styles.title}>ЕЩЕ ОПЦИИ <div className={styles.titleSpan}>* необязательно</div></div>
          <div className={styles.optionBlock}>
            {options.map((option, i) => (
              <div key={option.name} className={styles.optionItem}>
                <img
                  id={`option${i + 1}-${index}`}
                  src={option.image}
                  name={option.name}
                  alt=""
                  onClick={handleOption}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default SizeUp
