import React from "react"
import styles from "./UserConf.module.css"
import fasonsDown from "../data/fasonsDown"
import colors from "../data/colors"
import options from "../data/options"

const SizeDown = ({
  index,
  womanState,
  handleFasonDown,
  clearFasonDown,
  removeFasonDown,
  handleColorDown,
  clearColorDown,
  handleOptionDown,
  clearOptionDown,
  checkFormulaDown,
}) => {
  const fasonDownArr = womanState.fason_down
  const colorDownArr = womanState.color_down
  const optionDownArr = womanState.option_down

  return (
    <div className={`${styles.sizeBlock}  ${styles.sizeBlockNiz}`}>
      <div className={styles.name}>НИЗ</div>
      {index > 0 && (
        <button
          type="button"
          className={styles.removeButton}
          id={`removeFasonDown-${index}`}
          onClick={removeFasonDown}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16"><path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"></path></svg>
        </button>
      )}

      {fasonDownArr[index].id && (
        <>
          <div className={styles.selected}>
            {fasonDownArr[index].id && (
              <div className={styles.selectedItem}>
                <img src={fasonDownArr[index].src} alt="" />
                {fasonDownArr[index].id && !colorDownArr[index].id && (
                  <button
                    id={`clearFasonDown-${index}`}
                    onClick={clearFasonDown}
                    type="button"
                  >
                    Поменять фасон
                  </button>
                )}
              </div>
            )}
            {colorDownArr[index].id && (
              <div className={styles.selectedItem}>
                <img src={colorDownArr[index].src} alt="" />
                {colorDownArr[index].id && !optionDownArr[index].id && (
                  <button
                    id={`clearColorDown-${index}`}
                    onClick={clearColorDown}
                    type="button"
                  >
                    Поменять расцветку
                  </button>
                )}
              </div>
            )}
            {optionDownArr[index].id && (
              <div className={styles.selectedItem}>
                <img src={optionDownArr[index].src} alt="" />
              </div>
            )}
          </div>
          {optionDownArr[index].id && (
            <div>
              <button
                id={`clearOptionDown-${index}`}
                onClick={clearOptionDown}
                type="button"
                className={styles.changed}
              >
                Убрать опции
              </button>
            </div>
          )}
        </>
      )}

      {!fasonDownArr[index].id && (
        <div className={styles.sizeFasonNiz}>
          <div className={styles.title}>КАКОЙ БУДЕТ ФАСОН?</div>
          <div className={styles.fasonNizBlock}>
            {fasonsDown.map((fason, i) => (
              <div key={fason.name} className={styles.fasonNizItem}>
                <img
                  id={`fasonDown${i + 1}-${index}`}
                  src={fason.image}
                  name={fason.name}
                  alt=""
                  onClick={handleFasonDown}
                />
              </div>
            ))}
          </div>
          {checkFormulaDown ? null : <div className={styles.overlay}></div>}
        </div>
      )}

      {fasonDownArr[index].id && !colorDownArr[index].id && (
        <div className={styles.sizeColor}>
          <div className={styles.title}>КАКОЙ БУДЕТ РАСЦВЕТКА?</div>
          <div className={styles.colorBlock}>
            {colors.map((color, i) => (
              <div key={`down-${color.name}`} className={styles.colorItem}>
                <img
                  id={`colorDown${i + 1}-${index}`}
                  src={color.image}
                  name={color.name}
                  alt=""
                  onClick={handleColorDown}
                />
              </div>
            ))}
          </div>
        </div>
      )}

      {colorDownArr[index].id && !optionDownArr[index].id && (
        <div className={styles.sizeOption}>
          <div className={styles.title}>ЕЩЕ ОПЦИИ <div className={styles.titleSpan}>* необязательно</div></div>
          <div className={styles.optionBlock}>
            {options.map((option, i) => {
              if (i > 0) {
                return (
                  <div key={`down-${option.name}`} className={styles.optionItem}>
                    <img
                      id={`optionDown${i + 1}-${index}`}
                      src={option.image}
                      name={option.name}
                      alt=""
                      onClick={handleOptionDown}
                    />
                  </div>
                )
              } else {
                return null
              }
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default SizeDown
