const initialWoman = {
  product_up_id: "43692",
  sizes_up: {
    obhvat: 0,
    obhvat_pod: 0,
    obhvat_naklon: 0,
  },
  fason_up: [{
    index: 0,
    id: "",
    src: "",
    name: "",
  }],
  color_up: [{
    index: 0,
    id: "",
    src: "",
    name: "",
  }],
  option_up: [{
    index: 0,
    id: "",
    src: "",
    name: "",
  }],
  // option_up_additional: [{
  //   index: 0,
  //   id: "",
  //   src: "",
  //   name: "",
  // }],
  product_down_id: "43692",
  sizes_down: {
    obhvat_talia: 0,
    obhvat_yagod: 0,
  },
  fason_down: [{
    index: 0,
    id: "",
    src: "",
    name: "",
  }],
  color_down: [{
    index: 0,
    id: "",
    src: "",
    name: "",
  }],
  option_down: [{
    index: 0,
    id: "",
    src: "",
    name: "",
  }],
}

let indexUp = 0
let indexDown = 0

function womanReducer(womanState, action) {
  switch (action.type) {
    case "add_sizes_up": {
      return {
        ...womanState,
        sizes_up: {
          obhvat: action.obhvat,
          obhvat_pod: action.obhvat_pod,
          obhvat_naklon: action.obhvat_naklon,
        }
      }
    }
    case "add_up": {
      indexUp++
      return {
        ...womanState,
        fason_up: [
          ...womanState.fason_up,
          {
            index: indexUp,
            id: action.id,
            src: action.src,
            name: action.name,
          },
        ],
        color_up: [
          ...womanState.color_up,
          {
            index: indexUp,
            id: action.id,
            src: action.src,
            name: action.name,
          },
        ],
        option_up: [
          ...womanState.option_up,
          {
            index: indexUp,
            id: action.id,
            src: action.src,
            name: action.name,
          },
        ],
        // option_up_additional: [
        //   ...womanState.option_up_additional,
        //   {
        //     index: indexUp,
        //     id: action.id,
        //     src: action.src,
        //     name: action.name,
        //   },
        // ]
      }
    }
    case "add_fason_up": {
      return {
        ...womanState,
        fason_up: womanState.fason_up.map((item) => {
          if (item.index === +action.id.split("-")[1]) {
            if (action.id.split("-")[0] !== "clearFason") {
              return {
                ...womanState.fason_up[item.index],
                id: action.id,
                src: action.src,
                name: action.name,
              }
            }
            return {
              ...womanState.fason_up[item.index],
              id: "",
              src:"",
              name: "",
            }
          } else {
            return item
          }
        })
      }
    }
    case "add_color_up": {
      return {
        ...womanState,
        color_up: womanState.color_up.map((item) => {
          if (item.index === +action.id.split("-")[1]) {
            if (action.id.split("-")[0] !== "clearColor") {
              return {
                ...womanState.color_up[item.index],
                id: action.id,
                src: action.src,
                name: action.name,
              }
            }
            return {
              ...womanState.color_up[item.index],
              id: "",
              src: "",
              name: "",
            }
          } else {
            return item
          }
        })
      }
    }
    case "add_option_up": {
      return {
        ...womanState,
        option_up: womanState.option_up.map((item) => {
          if (item.index === +action.id.split("-")[1]) {
            if (action.id.split("-")[0] !== "clearOption") {
              return {
                ...womanState.option_up[item.index],
                id: action.id,
                src: action.src,
                name: action.name,
              }
            }
            return {
              ...womanState.option_up[item.index],
              id: "",
              src: "",
              name: "",
            }
          } else {
            return item
          }
        })
      }
    }
    // case "add_option_up_additional": {
    //   return {
    //     ...womanState,
    //     option_up_additional: womanState.option_up_additional.map((item) => {
    //       if (item.index === +action.id.split("-")[1]) {
    //         if (action.id.split("-")[0] !== "clearOption") {
    //           return {
    //             ...womanState.option_up_additional[item.index],
    //             id: action.id,
    //             src: action.src,
    //             name: action.name,
    //           }
    //         }
    //         return {
    //           ...womanState.option_up_additional[item.index],
    //           id: "",
    //           src: "",
    //           name: "",
    //         }
    //       } else {
    //         return item
    //       }
    //     })
    //   }
    // }
    case "add_sizes_down": {
      return {
        ...womanState,
        sizes_down: {
          obhvat_talia: action.obhvat_talia,
          obhvat_yagod: action.obhvat_yagod,
        }
      }
    }
    case "add_down": {
      indexDown++
      return {
        ...womanState,
        fason_down: [
          ...womanState.fason_down,
          {
            index: indexDown,
            id: action.id,
            src: action.src,
            name: action.name,
          },
        ],
        color_down: [
          ...womanState.color_down,
          {
            index: indexDown,
            id: action.id,
            src: action.src,
            name: action.name,
          },
        ],
        option_down: [
          ...womanState.option_down,
          {
            index: indexDown,
            id: action.id,
            src: action.src,
            name: action.name,
          },
        ]
      }
    }
    case "add_fason_down": {
      return {
        ...womanState,
        fason_down: womanState.fason_down.map((item) => {
          if (item.index === +action.id.split("-")[1]) {
            if (action.id.split("-")[0] !== "clearFasonDown") {
              return {
                ...womanState.fason_down[item.index],
                id: action.id,
                src: action.src,
                name: action.name,
              }
            }
            return {
              ...womanState.fason_down[item.index],
              id: "",
              src: "",
              name: "",
            }
          } else {
            return item
          }
        })
      }
    }
    case "add_color_down": {
      return {
        ...womanState,
        color_down: womanState.color_down.map((item) => {
          if (item.index === +action.id.split("-")[1]) {
            if (action.id.split("-")[0] !== "clearColorDown") {
              return {
                ...womanState.color_down[item.index],
                id: action.id,
                src: action.src,
                name: action.name,
              }
            }
            return {
              ...womanState.color_down[item.index],
              id: "",
              src: "",
              name: "",
            }
          } else {
            return item
          }
        })
      }
    }
    case "add_option_down": {
      return {
        ...womanState,
        option_down: womanState.option_down.map((item) => {
          if (item.index === +action.id.split("-")[1]) {
            if (action.id.split("-")[0] !== "clearOptionDown") {
              return {
                ...womanState.option_down[item.index],
                id: action.id,
                src: action.src,
                name: action.name,
              }
            }
            return {
              ...womanState.option_down[item.index],
              id: "",
              src:"",
              name: "",
            }
          } else {
            return item
          }
        })
      }
    }
    case "remove_up": {
      indexUp--

      return {
        ...womanState,
        fason_up: womanState.fason_up.filter((item) => {
          return item.index !== +action.id.split("-")[1]
        }),
        color_up: womanState.color_up.filter((item) => {
          return item.index !== +action.id.split("-")[1]
        }),
        option_up: womanState.option_up.filter((item) => {
          return item.index !== +action.id.split("-")[1]
        }),
      }
    }
    case "remove_down": {
      indexDown--

      return {
        ...womanState,
        fason_down: womanState.fason_down.filter((item) => {
          return item.index !== +action.id.split("-")[1]
        }),
        color_down: womanState.color_down.filter((item) => {
          return item.index !== +action.id.split("-")[1]
        }),
        option_down: womanState.option_down.filter((item) => {
          return item.index !== +action.id.split("-")[1]
        })
      }
    }
    case "clear_all": {
      return {
        ...womanState,
        ...initialWoman,
      }
    }
    default: {
      throw Error("Unknown action: " + action.type)
    }
  }
}

export { initialWoman, womanReducer }
