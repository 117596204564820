import option1 from "../images/option-1.png"
import option2 from "../images/option-2.png"
import option3 from "../images/option-3.png"
// import option4 from "../images/option-4.png"

const options = [
  {
    name: `Кружевная вставка (верх)`,
    image: option1,
  },
  {
    name: `Сеточка-горошек`,
    image: option2,
  },
  {
    name: `Кружево по вырезу`,
    image: option3,
  },
  // {
  //   name: `Кружевная вставка (низ)`,
  //   image: option4,
  // },
]

export default options