import woman from "../images/woman.png"
import baby from "../images/baby.png"
import pregnant from "../images/pregnant.png"
import man from "../images/man.png"

const persons = [
  woman,
  man,
  pregnant,
  baby,
]

export default persons