import React, { useState, useReducer, useEffect, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { useCookies } from "react-cookie"
import CoCart from "../services/co-cart-api"
import { useForm } from "react-hook-form"
import { initialWoman, womanReducer } from "../store/womanReducer"
import styles from "./UserConf.module.css"
import { ReactComponent as Close } from "../images/close.svg"
import { ReactComponent as Plus } from "../images/mini-plus.svg"
import SizeBlock from "./SizeBlock"
import persons from "../data/persons"

const UserConf = () => {
  const [womanState, dispatch] = useReducer(womanReducer, initialWoman)
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    reset
  } = useForm()
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(false)
  const [cookies, setCookie] = useCookies(["cart_key"])
  const [sewing, setSewing] = useState("")
  const [checkFormulaUp, setCheckFormulaUp] = useState(false)
  const [checkFormulaDown, setCheckFormulaDown] = useState(false)
  

  const geCartKey = useCallback(async () => {
    try {
      const response = await CoCart.get("cart")

      if (!cookies.cart_key) {
        setCookie("cart_key", response.data.cart_key, {
          maxAge: 86400,
          secure: true,
          sameSite: "none",
        })
      }
    } catch (err) {
      console.log(err)
    }
  }, [cookies.cart_key, setCookie])

  useEffect(() => {
    geCartKey()
  }, [geCartKey])

  let sec = +(womanState.fason_up.length + "000")

  async function getUser(state) {
    try {
      setLoading(true)

      let dataUp = {}
      let obhvat = state.sizes_up.obhvat
      let obhvat_pod = state.sizes_up.obhvat_pod
      let obhvat_naklon = state.sizes_up.obhvat_naklon

      let dataDown = {}
      let obhvat_talia = state.sizes_down.obhvat_talia
      let obhvat_yagod = state.sizes_down.obhvat_yagod
      
      if (obhvat && obhvat_pod && obhvat_naklon) {
        for (let i = 0; i < state.fason_up.length; i++) {
          dataUp = {
            id: state.product_up_id,
            quantity: "1",
            variation: {
              "attribute_pa_size": "individualnyj-poshiv",
            },
            item_data: {
              custom_data: {
                SKU: "неонкотыМ1Т10",
                "obhvat_grydi": obhvat.toString(),
                "obhvat_pod_grydiy": obhvat_pod.toString(),
                "obhvat_grydi_v_naklone": obhvat_naklon.toString(),
                "fason_up": state.fason_up[i].name,
                "color_up": state.color_up[i].name,
                "option_up": state.option_up[i].name || "",
              }
            }
          }

          const response = await CoCart.post(`cart/add-item?cart_key=${cookies.cart_key}`, dataUp)
          console.log(response)
        }
      }

      if (obhvat_yagod) {
        for (let i = 0; i < state.fason_down.length; i++) {
          dataDown = {
            id: state.product_down_id,
            quantity: "1",
            variation: {
              "attribute_pa_size": "individualnyj-poshiv",
            },
            item_data: {
              custom_data: {
                SKU: "клубникаМ1комфорт",
                "obhvat_yagodits": obhvat_yagod.toString(),
                "waist_circumference": obhvat_talia.toString(),
                "fason_down": state.fason_down[i].name,
                "color_down": state.color_down[i].name,
                "option_down": state.option_down[i].name || "",
              }
            }
          }

          const response = await CoCart.post(`cart/add-item?cart_key=${cookies.cart_key}`, dataDown)
          console.log(response)
        }
      }    
    } catch (error) {
      console.error(error)
    }
  }

  const onSubmit = async (data) => {
    console.log(data)

    getUser(womanState)

    setTimeout(() => {
      setLoading(false)
      reset()
      handleClearClick()
    }, sec)
  }

  const handleChange = (e) => {
    (e.target.id === "size") ? setSewing("size") : setSewing("individ")
  }

  const handleUpChange = (e) => {
    let obhvat = womanState.sizes_up.obhvat
    let obhvat_pod = womanState.sizes_up.obhvat_pod
    let obhvat_naklon = womanState.sizes_up.obhvat_naklon

    function checkFormula(obhvat, obhvat_pod, obhvat_naklon, regField) {
      const min = 8
      const max = 25

      let obhvat_num = +obhvat
      let obhvat_pod_num = +obhvat_pod
      let obhvat_naklon_num = +obhvat_naklon

      let res = (obhvat_num + obhvat_naklon_num) / 2 - obhvat_pod_num
      console.log(res)

      if (min > res) {
        setCheckFormulaUp(false)

        if (regField === "obhvat") {
          let obhvatValue = (min + obhvat_pod_num - obhvat_naklon_num / 2) * 2
          
          setError(
            regField,
            {
              type: "custom",
              message: `Установите значение в ${obhvatValue} и больше`
            }
          )
        }

        if (regField === "obhvat-pod") {
          let obhvatPodValue = (obhvat_num + obhvat_naklon_num) / 2 - min

          setError(
            regField,
            {
              type: "custom",
              message: `Установите значение в ${obhvatPodValue} или меньше`
            }
          )
        }

        if (regField === "obhvat-naklon") {
          let obhvatNaklonValue = (min + obhvat_pod_num - obhvat_num / 2) * 2

          setError(
            regField,
            {
              type: "custom",
              message: `Установите значение в ${obhvatNaklonValue} или больше`
            }
          )
        }
      } else if (max < res) {
        setCheckFormulaUp(false)
  
        if (regField === "obhvat") {
          let obhvatValue = (max + obhvat_pod_num - obhvat_naklon_num / 2) * 2
          
          setError(
            regField,
            {
              type: "custom",
              message: `Установите значение в ${obhvatValue} или меньше`
            }
          )
        }

        if (regField === "obhvat-pod") {
          let obhvatPodValue = (obhvat_num + obhvat_naklon_num) / 2 - max

          setError(
            regField,
            {
              type: "custom",
              message: `Установите значение в ${obhvatPodValue} или больше`
            }
          )
        }

        if (regField === "obhvat-naklon") {
          let obhvatNaklonValue = (max + obhvat_pod_num - obhvat_num / 2) * 2

          setError(
            regField,
            {
              type: "custom",
              message: `Установите значение в ${obhvatNaklonValue} или меньше`
            }
          )
        }
      } else {
        clearErrors(regField)

        if (obhvat_num && obhvat_pod_num && obhvat_naklon_num) {
          setCheckFormulaUp(true)
        }
      }
    }

    if (e.target.id === "obhvat") {
      let num = +e.target.value

      if ((num < 79 || num > 115) && num !== 0) {
        setCheckFormulaUp(false)

        setError(
          "obhvat",
          {
            type: "custom",
            message: "Значение не может быть меньше 79 и больше 115"
          }
        )
      } else if ((num >= 79 || num <= 115) && obhvat_pod && obhvat_naklon) {
        checkFormula(obhvat = num, obhvat_pod, obhvat_naklon, "obhvat")
      } else {
        clearErrors("obhvat")
      }

      dispatch({
        type: "add_sizes_up",
        ...womanState.sizes_up,
        obhvat: +e.target.value,
      })
    }

    if (e.target.id === "obhvat-pod") {
      let num = +e.target.value

      if ((num < 67 || num > 101) && num !== 0) {
        setCheckFormulaUp(false)

        setError(
          "obhvat-pod",
          {
            type: "custom",
            message: "Значение не может быть меньше 67 и больше 101"
          }
        )
      } else if ((num >= 67 || num <= 101) && obhvat && obhvat_naklon) {
        checkFormula(obhvat, obhvat_pod = num, obhvat_naklon, "obhvat-pod")
      } else {
        clearErrors("obhvat-pod")
      }

      dispatch({
        type: "add_sizes_up",
        ...womanState.sizes_up,
        obhvat_pod: +e.target.value,
      })
    }

    if  (e.target.id === "obhvat-naklon") {
      let num = +e.target.value

      if ((num < 79 || num > 135) && num !== 0) {
        setCheckFormulaUp(false)

        setError(
          "obhvat-naklon",
          {
            type: "custom",
            message: "Значение не может быть меньше 79 и больше 135"
          }
        )
      } else if ((num >= 79 || num <= 135) && obhvat && obhvat_pod) {
        checkFormula(obhvat, obhvat_pod, obhvat_naklon = num, "obhvat-naklon")
      } else {
        clearErrors("obhvat-naklon")
      }

      dispatch({
        type: "add_sizes_up",
        ...womanState.sizes_up,
        obhvat_naklon: +e.target.value,
      })
    }
  }

  const handleDownChange = (e) => {
    let obhvat_talia = womanState.sizes_down.obhvat_talia
    let obhvat_yagod = womanState.sizes_down.obhvat_yagod

    if (e.target.id === "obhvat-talia") {
      let num = +e.target.value

      if ((num < 80 || num > 140) && num !== 0) {
        setCheckFormulaDown(false)

        setError(
          "obhvat-talia",
          {
            type: "custom",
            message: "Значение не может быть меньше 80 и больше 140"
          }
        )
      } else {
        clearErrors("obhvat-talia")

        if (obhvat_talia && (84 < obhvat_yagod && obhvat_yagod < 121)) {
          setCheckFormulaDown(true)
        }
      }

      dispatch({
        type: "add_sizes_down",
        ...womanState.sizes_down,
        obhvat_talia: +e.target.value,
      })
    } 

    if  (e.target.id === "obhvat-yagod") {
      let num = +e.target.value

      if ((num < 85 || num > 121) && num !== 0) {
        setCheckFormulaDown(false)

        setError(
          "obhvat-yagod",
          {
            type: "custom",
            message: "Значение не может быть меньше 85 и больше 121"
          }
        )
      } else {
        clearErrors("obhvat-yagod")

        if (obhvat_yagod && (79 < obhvat_talia && obhvat_talia < 141)) {
          setCheckFormulaDown(true)
        }
      }

      dispatch({
        type: "add_sizes_down",
        ...womanState.sizes_down,
        obhvat_yagod: +e.target.value,
      })
    }
  }

  const handleClearClick = () => {
    dispatch({
      type: "clear_all",
      ...womanState,
      ...initialWoman
    })

    setSewing("")
    navigate("/")
  }

  return (
    <div className={styles.wrapper}>
      {loading && (
        <>
          <div className="loader"></div>
          <div className="overlay"></div>
        </>
      )}

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.formData}>
          <button onClick={handleClearClick} type="button" className={styles.close}>
            <Close />
          </button>
          <div className={styles.userChange}>
            <div className={styles.userAdd}>
              <img src={persons[0]} alt="Woman" />
            </div>
            <div className={styles.addPerson}>
              <Plus className={styles.addPersonSvg} />
            </div>
            <div className={styles.addPersonAnother}>
              <button type="button" className={styles.buttonPerson}>
                <img src={persons[0]} alt="" />
              </button>
              <button type="button" className={styles.buttonPerson}>
                <img src={persons[1]} alt="" />
                <div className="overlay"></div>
              </button>
              <button type="button" className={styles.buttonPerson}>
                <img src={persons[2]} alt="" />
                <div className="overlay"></div>
              </button>
              <button type="button" className={styles.buttonPerson}>
                <img src={persons[3]} alt="" />
                <div className="overlay"></div>
              </button>
            </div>
          </div>

          {sewing === "" && (
            <div className={`${styles.sizeBlock} ${styles.sizeChoice}`}>
              <div className={styles.name}>ВВЕДИТЕ МЕРКИ</div>
              <div className={styles.title}>ВЫБЕРЕТЕ ТИП ПОШИВА</div>
              <div className={styles.choiceSize}>
                <button
                  id="individ"
                  type="button"
                  onClick={handleChange}
                >
                  ИНДИВИДУАЛЬНЫЙ ПОШИВ
                </button>
                <button
                  id="size"
                  type="button"
                  onClick={handleChange}
                  disabled
                >
                  РАЗМЕРНАЯ СЕТКА
                  <div className={styles.overlaySize}></div>
                </button>
                
              </div>
            </div>
          )}

          {sewing === "individ" && (
            <div className={`${styles.sizeBlock} ${styles.sizeEnter}`}>
              <div className={styles.name}>ВВЕДИТЕ МЕРКИ</div>
              <div className={styles.enterSizes}>
                <div className={styles.enterVerh}>
                  <div className={styles.inputBlock}>
                    <h4>ОБХВАТ ГРУДИ</h4>
                    <input
                      {...register("obhvat")}
                      id="obhvat" 
                      name="obhvat" 
                      type="number"
                      onChange={handleUpChange}
                    />
                  </div>
                  {errors["obhvat"] &&
                    <span className={styles.error}>{errors["obhvat"].message}</span>
                  }
                  <div className={styles.inputBlock}>
                    <h4>ОБХВАТ ПОД ГРУДЬЮ</h4>
                    <input
                      {...register("obhvat-pod")}
                      id="obhvat-pod"
                      name="obhvat-pod"
                      type="number"
                      onChange={handleUpChange}
                    />
                  </div>
                  {errors["obhvat-pod"] &&
                    <span className={styles.error}>{errors["obhvat-pod"].message}</span>
                  }
                  <div className={styles.inputBlock}>
                    <h4>ОБХВАТ ГРУДИ В НАКЛОНЕ</h4>
                    <input
                      {...register("obhvat-naklon")}
                      id="obhvat-naklon"
                      name="obhvat-naklon"
                      type="number"
                      onChange={handleUpChange}
                    />
                  </div>
                  {errors["obhvat-naklon"] &&
                    <span className={styles.error}>{errors["obhvat-naklon"].message}</span>
                  }
                </div>
                <div className={styles.enterNiz}>
                  <div className={styles.inputBlock}>
                    <h4>ОБХВАТ ТАЛИИ</h4>
                    <input
                      {...register("obhvat-talia")}
                      id="obhvat-talia"
                      name="obhvat-talia"
                      type="number"
                      onChange={handleDownChange}
                    />
                  </div>
                  {errors["obhvat-talia"] &&
                    <span className={styles.error}>{errors["obhvat-talia"].message}</span>
                  }
                  <div className={styles.inputBlock}>
                    <h4>ОБХВАТ ЯГОДИЦ</h4>
                    <input
                      {...register("obhvat-yagod")}
                      id="obhvat-yagod"
                      name="obhvat-yagod"
                      type="number"
                      onChange={handleDownChange}
                    />
                  </div>
                  {errors["obhvat-yagod"] &&
                    <span className={styles.error}>{errors["obhvat-yagod"].message}</span>
                  }
                </div>
              </div>
            </div>
          )}

          <SizeBlock
            checkFormulaUp={checkFormulaUp}
            checkFormulaDown={checkFormulaDown}
            sewing={sewing}
            womanState={womanState}
            dispatch={dispatch}
          />
          
        </div>

        {(womanState.fason_up[0].id && womanState.color_up[0].id)
          || (womanState.fason_down[0].id && womanState.color_down[0].id) ? (
          <div className={styles.formButton}>
            <button type="submit">Добавить в корзину</button>
          </div>
          ) : (
          <div className={styles.formButton}>
            <button disabled>Добавить в корзину</button>
          </div>
        )}
      </form>
      
    </div>
  )
}

export default UserConf
