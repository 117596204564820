import niz1 from "../images/niz-1.png"
import niz2 from "../images/niz-2.png"
import niz3 from "../images/niz-3.png"
import niz4 from "../images/niz-4.png"
import niz5 from "../images/niz-5.png"
import niz6 from "../images/niz-6.png"
import niz7 from "../images/niz-7.png"
import niz8 from "../images/niz-8.png"

const fasonsDown = [
  {
    name: `Трусики низкой посадки фасона “Т1”`,
    image: niz1,
  },
  {
    name: `Трусики высокой посадки фасона “Т2”`,
    image: niz2,
  },
  {
    name: `Трусики средней посадки фасона “Т10”`,
    image: niz3,
  },
  {
    name: `Бразильяно с тонким кружевом фасона “Т3”`,
    image: niz4,
  },
  {
    name: `Стринги низкой посадки фасона “Т4”`,
    image: niz5,
  },
  {
    name: `Бразильяно с широким кружевом фасона “Т6”`,
    image: niz6,
  },
  {
    name: `Стринги высокой посадки фасона “Т5”`,
    image: niz7,
  },
  {
    name: `Трусики-шортики фасона “Т8”`,
    image: niz8,
  },
]

export default fasonsDown